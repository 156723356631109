import { QuotesIcon } from './icons/Quotes'
import { Overline } from './Overline'
import { Paragraph } from './Paragraph'

export const Quote = ({
  quote,
  author,
  children,
}: {
  quote?: React.ReactNode
  author?: React.ReactNode
  children?: React.ReactNode
}) => {
  return (
    <article className="flex gap-16 flex-col md:flex-row">
      <span className="text-highlight w-64">
        <QuotesIcon />
      </span>
      <blockquote className="grid gap-16 content-start">
        {quote && <Paragraph large>{quote}</Paragraph>}
        {author && <Overline>{author}</Overline>}
        {children}
      </blockquote>
    </article>
  )
}
