'use client'

import { TestimonialsBlockQuery } from '.generated/dato'
import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { Overline } from 'components/Overline'
import { Scribble } from 'components/Scribble'
import scrollbar from 'components/Scrollbar.module.css'
import { Testimonial } from 'components/Testimonial'
import { Link } from 'i18n/routing'
import { cx } from 'lib/cx'
import { requestCallback } from 'lib/requestCallback'
import { useTranslations } from 'next-intl'
import { useEffect, useRef, useState } from 'react'
import styles from './Testimonials.module.css'

interface Props {
  content: NonNullable<TestimonialsBlockQuery['testimonialsBlock']>
}

export function Testimonials({ content }: Props) {
  const [activeTestimonial, setActiveTestimonial] = useState(
    content.testimonials[0]!.id,
  )
  const [height, setHeight] = useState<'auto' | number>('auto')

  const testimonialContainerRef = useRef<HTMLDivElement>(null)
  const activeTestimonialRef = useRef<HTMLDivElement>(null)
  const brandContainerRef = useRef<HTMLElement>(null)
  const isScrolling = useRef<number | undefined>()

  const t = useTranslations('testimonials')

  function handleScrollTestimonials() {
    if (!activeTestimonialRef.current || !testimonialContainerRef.current) {
      return
    }

    const container = testimonialContainerRef.current

    if (
      Math.abs(activeTestimonialRef.current.offsetLeft - container.scrollLeft) <
      container.offsetWidth / 2
    ) {
      return
    }

    if (isScrolling.current) {
      return
    }

    requestCallback(() => {
      const targetTestimonial = Array.from(container.childNodes)
        .filter(isHtmlElement)
        .find((child) => {
          return (
            Math.abs(container.scrollLeft - child.offsetLeft) <=
            container.offsetWidth / 2
          )
        })

      if (!targetTestimonial) {
        return
      }

      setActiveTestimonial(targetTestimonial.getAttribute('data-id')!)
    })
  }

  useEffect(() => {
    if (!brandContainerRef.current) {
      return
    }

    if (!activeTestimonial) {
      return
    }

    scrollToChildCenter(brandContainerRef.current, activeTestimonial)
    const containerHeight = (
      activeTestimonialRef.current?.firstChild as HTMLDivElement
    ).offsetHeight

    const timeout = setTimeout(() => {
      setHeight(containerHeight ?? 'auto')
    }, 500)

    return () => clearTimeout(timeout)
  }, [activeTestimonial])

  return (
    <section className="bg-white overflow-x-hidden">
      <Container
        className="relative grid md:grid-flow-col py-80 md:py-160 overflow-hidden"
        width="full"
      >
        <div
          className="absolute inset-x-0 bottom-0 w-full h-80 md:h-160 z-10 bg-white"
          style={{
            maskImage:
              'linear-gradient(to top,rgba(0,0,0,1.0),transparent) 40%',
            WebkitMaskImage:
              'linear-gradient(to top,rgba(0,0,0,1.0),transparent 40%)',
          }}
        />
        <div className="grid md:justify-items-center items-center md:w-16 break-normal px-24 pb-24 md:pb-0">
          <Overline className="md:-rotate-90 inline-block md:-translate-x-1/2 whitespace-nowrap">
            {t('testimonials')}
          </Overline>
        </div>
        <div
          className="min-w-full lg:px-64 xl:px-80 overflow-visible transition-height duration-500"
          style={{ height }}
        >
          <div
            className={cx(
              scrollbar.hide,
              'min-w-full snap-mandatory snap-x flex flex-row overflow-x-auto',
            )}
            ref={testimonialContainerRef}
            onScroll={handleScrollTestimonials}
          >
            {content.testimonials.map((testimonial) => (
              <div
                key={testimonial.id}
                className="min-w-full snap-center highlight-petrol"
                data-id={testimonial.id}
                ref={
                  testimonial.id === activeTestimonial
                    ? activeTestimonialRef
                    : undefined
                }
              >
                <Testimonial testimonial={testimonial}>
                  {testimonial.caseStudy && (
                    <span className="pt-16">
                      <Link
                        href={{
                          pathname: '/projekte/[slug]',
                          params: { slug: testimonial.caseStudy.slug },
                        }}
                      >
                        <Button>{t('toTheProject')}</Button>
                      </Link>
                    </span>
                  )}
                </Testimonial>
              </div>
            ))}
          </div>
        </div>
      </Container>
      <div className="bg-black text-white">
        <Container width="full" className="relative">
          <div className={styles.gradient}></div>
          <nav
            className={cx(
              'w-full h-96 md:h-180 px-32',
              'overflow-x-auto',
              'flex flex-row md:gap-64 items-center justify-between',
              scrollbar.hide,
            )}
            ref={brandContainerRef}
          >
            {content.testimonials.map((testimonial) => (
              <button
                key={testimonial.id}
                onClick={() => {
                  setActiveTestimonial(testimonial.id)

                  // here comes an ugly hack to prevent the scroll callback from running while scrolling programmatically
                  clearTimeout(isScrolling.current)
                  isScrolling.current =
                    window &&
                    window.setTimeout(() => {
                      isScrolling.current = undefined
                    }, 500)

                  if (testimonialContainerRef.current) {
                    scrollToChild(
                      testimonialContainerRef.current,
                      testimonial.id,
                    )
                  }
                }}
                className={cx(
                  'py-16 px-32 md:px-64 opacity-60 relative',
                  testimonial.id === activeTestimonial && 'opacity-100',
                  testimonial.id === activeTestimonial &&
                    styles.withIndicatorArrow,
                )}
                data-id={testimonial.id}
                aria-label={testimonial.companyName!}
              >
                <Scribble
                  className="text-30"
                  color={
                    testimonial.id === activeTestimonial ? 'petrol' : 'white'
                  }
                >
                  {testimonial.companyName!}
                </Scribble>
              </button>
            ))}
          </nav>
          <div
            className={cx(styles.gradient, 'right-0 top-0 rotate-180')}
          ></div>
        </Container>
      </div>
    </section>
  )
}

function scrollToChild(container: HTMLElement, id: string) {
  const element = container.querySelector(`[data-id="${id}"]`)

  if (!isHtmlElement(element)) {
    return
  }

  container.scrollTo({
    left: element.offsetLeft,
    behavior: 'smooth',
  })
}

function scrollToChildCenter(container: HTMLElement, id: string) {
  const element = container.querySelector(`[data-id="${id}"]`)

  if (!isHtmlElement(element)) {
    return
  }

  const elementCenter = element.offsetLeft + element.offsetWidth / 2

  const left = elementCenter - container.offsetWidth / 2

  container.scrollTo({
    left,
    behavior: 'smooth',
  })
}

function isHtmlElement(element: unknown): element is HTMLElement {
  return element instanceof HTMLElement
}
