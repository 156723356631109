import { cx } from 'lib/cx'
import React from 'react'

export type ParagraphProps = {
  className?: string
  children: React.ReactNode
} & (
  | {
      shortcopy: true
    }
  | {
      longcopy: true
    }
  | {
      highlight: true
    }
  | {
      large: true
    }
)

export function Paragraph(props: ParagraphProps) {
  return (
    <p className={cx('whitespace-pre-wrap', className(props), props.className)}>
      {props.children}
    </p>
  )
}

function className(props: ParagraphProps) {
  switch (true) {
    case 'shortcopy' in props && props.shortcopy:
      return 'text-20 tracking-20 leading-26 font-light'
    case 'longcopy' in props && props.longcopy:
      return 'text-20 tracking-20 leading-30 font-light'
    case 'highlight' in props && props.highlight:
      return 'text-20 tracking-20 leading-30 font-regular'
    case 'large' in props && props.large:
      return 'text-22 leading-28 font-light lg:text-30 lg:leading-39'
  }
}
