import { cx } from 'lib/cx'
import dynamic from 'next/dynamic'
import React from 'react'
import Finanzcheck from './Finanzcheck'
import authorizedSignatorySvg from 'public/scribbles/authorized_signatory.svg'
import ceoSvg from 'public/scribbles/ceo.svg'
import newBusinessSvg from 'public/scribbles/new_business.svg'
import projectManagementSvg from 'public/scribbles/project_management.svg'
import softwareDevelopmentSvg from 'public/scribbles/software_development.svg'
import successAssuranceSvg from 'public/scribbles/success_assurance.svg'
import Image, { StaticImageData } from 'next/image'

const scribbleComponents: Record<
  string,
  React.ElementType<{ className?: string; alt?: string }>
> = {
  farbenmeer: dynamic(() => import('./Farbenmeer')),
  Xing: dynamic(() => import('./Xing')),
  Accenture: dynamic(() => import('./Accenture')),
  bonprix: dynamic(() => import('./Bonprix')),
  'Veta Health': dynamic(() => import('./VetaHealth')),
  Bemer: dynamic(() => import('./Bemer')),
  Caspar: dynamic(() => import('./Caspar')),
  Otto: dynamic(() => import('./Otto')),
  SinnerSchrader: dynamic(() => import('./Sinnerschrader')),
  Smava: dynamic(() => import('./Smava')),
  tolingo: dynamic(() => import('./Tolingo')),
  Joyn: dynamic(() => import('./Joyn')),
  tbf: dynamic(() => import('./Tbf')),
  '17Ziele': dynamic(() => import('./17Ziele')),
  Evernest: dynamic(() => import('./Evernest')),
  Geschäftsführung: dynamic(() => import('./Executive')),
  Softwareentwicklung: dynamic(() => import('./SoftwareEngineering')),
  'Melde dich bei Roman': dynamic(() => import('./ContactRoman')),
  'Melde dich bei Elisa': dynamic(() => import('./ContactElisa')),
  'Melde dich bei Rebecca': dynamic(() => import('./ContactRebecca')),
  Prokura: dynamic(() => import('./Prokura')),
  'Lean & Agile Coaching': dynamic(() => import('./LeanAgileCoaching')),
  'UX & UI Design': dynamic(() => import('./UxUiDesign')),
  Erfolgssicherung: dynamic(() => import('./AssuranceOfSuccess')),
  Bürohund: dynamic(() => import('./OfficeDog')),
  Projektmanagement: dynamic(() => import('./Projectmanagement')),
  'Engagement Global': dynamic(() => import('./EngagementGlobal')),
  'FINANZCHECK.de': Finanzcheck,
  'Authorized signatory': makeSvgScribble(authorizedSignatorySvg),
  CEO: makeSvgScribble(ceoSvg),
  'Software development': makeSvgScribble(
    softwareDevelopmentSvg,
    'h-[1.5em] w-fit',
  ),
  'Success assurance': makeSvgScribble(successAssuranceSvg),
  'Project management': makeSvgScribble(
    projectManagementSvg,
    'h-[1.3em] w-fit',
  ),
  'New Business': makeSvgScribble(newBusinessSvg),
}

type ScribbleProps = {
  children: string
  className?: string
  color?: 'petrol' | 'white' | 'black'
}

export function Scribble({
  children,
  className,
  color = 'black',
}: ScribbleProps) {
  const ScribbleComponent = scribbleComponents[children]

  if (ScribbleComponent) {
    return (
      <ScribbleComponent
        alt={children}
        className={cx(
          className,
          'filter',
          color === 'petrol' && 'grayscale-0 text-petrol',
          color === 'white' && 'grayscale brightness-0 invert text-white',
          color === 'black' && 'grayscale brightness-[16.1%] text-black',
        )}
      />
    )
  }

  return (
    <div
      className={cx(
        className,
        'whitespace-pre',
        color === 'petrol' && 'text-petrol',
        color === 'white' && 'text-white',
        color === 'black' && 'text-black',
      )}
    >
      {children}
    </div>
  )
}

function makeSvgScribble(src: StaticImageData, className?: string) {
  return function SvgScribble(props: { className?: string; alt?: string }) {
    return (
      <Image
        src={src}
        alt={props.alt!}
        className={cx(props.className, className ?? 'h-[1em] w-fit')}
      />
    )
  }
}
