'use client'
import React from 'react'
import giesskanneInitial from 'public/illustrations/giesskanne.initial.svg'
import giesskanneFinal from 'public/illustrations/giesskanne.final.svg'
import giesskanne from 'public/illustrations/giesskanne.svg'
import Image, { StaticImageData } from 'next/image'

const isSafari =
  typeof window !== 'undefined' &&
  ('safari' in window ||
    (/safari\//i.test(window.navigator.userAgent) &&
      !/chrome\//i.test(window.navigator.userAgent)))

export function ViewportTriggeredAnimation(props: {
  src: StaticImageData
  thumb: StaticImageData
  staticFallback: StaticImageData
  alt: string
}) {
  const ref = React.useRef<HTMLImageElement>(null)
  const [triggered, setTriggered] = React.useState(false)
  const [supportsAnimation, setSupportsAnimation] = React.useState(true)

  React.useEffect(() => {
    if (ref.current === null) {
      return
    }

    if (isSafari) {
      setSupportsAnimation(false)
      setTriggered(true)
      return
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry?.isIntersecting) {
          setTriggered(true)
          observer.disconnect()
        }
      },
      {
        rootMargin: '0px',
        threshold: 1,
      },
    )

    observer.observe(ref.current)

    return () => {
      observer.disconnect()
    }
  }, [])

  return (
    <span ref={ref}>
      <Image
        priority={triggered}
        unoptimized
        src={
          !triggered
            ? props.thumb
            : supportsAnimation
              ? props.src
              : props.staticFallback
        }
        alt={props.alt}
      />
    </span>
  )
}

export function Giesskanne() {
  return (
    <ViewportTriggeredAnimation
      src={giesskanne}
      thumb={giesskanneInitial}
      staticFallback={giesskanneFinal}
      alt="animierte Giesskanne"
    />
  )
}
