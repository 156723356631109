function getRequestCallback(): (callback: () => void) => void {
  const isClient = typeof window !== 'undefined'

  if (isClient && 'requestIdleCallback' in window) {
    return (callback) =>
      (
        window as unknown as {
          requestIdleCallback: (
            callback: () => void,
            options: { timeout: number },
          ) => void
        }
      ).requestIdleCallback(callback, { timeout: 200 })
  }

  if (isClient && 'requestAnimationFrame' in window) {
    return (callback) => window.requestAnimationFrame(callback)
  }

  return (callback) => setTimeout(callback)
}

export const requestCallback = getRequestCallback()
