export function QuotesIcon({ ...rest }: JSX.IntrinsicElements['svg']) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44.219"
      height="33.662"
      viewBox="0 0 44.219 33.662"
      {...rest}
    >
      <title>&quot;</title>
      <g
        id="Gruppe_2885"
        data-name="Gruppe 2885"
        transform="translate(-2598.602 -2156.557)"
      >
        <path
          id="Pfad_2550"
          data-name="Pfad 2550"
          d="M2601.409,2190.1c-1.171.151-2.229.3-2.682-.982a2.657,2.657,0,0,1,1.284-3.061c3.514-2.3,5.479-5.819,7.33-9.446.454-.869.3-1.474-.755-1.852-2.985-1.02-4.534-3.627-6.121-6.045-1.436-2.229-.869-5.63.906-7.633a15.2,15.2,0,0,1,5.063-3.816,6.015,6.015,0,0,1,5.819.075,16.1,16.1,0,0,1,4.383,3.137,6.862,6.862,0,0,1,1.663,6.725,32.049,32.049,0,0,1-3.816,9.522,51.638,51.638,0,0,0-2.834,5.366,24.92,24.92,0,0,1-4.837,6.2A5.8,5.8,0,0,1,2601.409,2190.1Z"
          fill="currentColor"
        />
        <path
          id="Pfad_2551"
          data-name="Pfad 2551"
          d="M2642.636,2165c.454,4.27-1.738,7.859-3.552,11.562-.831,1.738-1.738,3.439-2.645,5.139a13.408,13.408,0,0,1-8.123,6.839,4.628,4.628,0,0,1-3.552-.189c-1.323-.68-1.738-2.342-.6-2.947,3.1-1.7,3.854-5.1,5.781-7.595a3.466,3.466,0,0,0,.265-.529c.453-.906,1.4-1.7,1.171-2.72-.264-1.247-1.662-.907-2.494-1.323a5.353,5.353,0,0,1-2.909-5.252,9.791,9.791,0,0,1,2.872-6.2,14.764,14.764,0,0,1,8.086-4.5,4.79,4.79,0,0,1,5.705,3.665A11.181,11.181,0,0,1,2642.636,2165Z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}
