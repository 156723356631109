import { SmallArrow } from 'components/icons/Arrow'
import { cx } from 'lib/cx'
import React from 'react'
import styles from 'components/Button.module.css'
import { Spinner } from 'components/Spinner'

export type ButtonProps = {
  fullWidth?: 'sm' | 'md' | 'lg' | false
  slim?: boolean
  arrow?: 'never' | 'md' | 'always'
  className?: string
  outlined?: boolean
  children: React.ReactNode
  loading?: boolean
}

export function Button({
  arrow = 'always',
  className,
  slim,
  fullWidth,
  children,
  outlined,
  loading,
  ...elementProps
}: ButtonProps & JSX.IntrinsicElements['button']) {
  return (
    <button
      {...elementProps}
      className={cx(
        styles.main,
        arrow === 'always' && styles.withArrow,
        arrow === 'md' && styles.withArrowAtMd,
        outlined && styles.outlined,
        className,
        slim && styles.slim,
        fullWidthClassName(fullWidth),
      )}
    >
      {loading ? (
        <span className="-my-4">
          <Spinner />
        </span>
      ) : (
        <>
          <span className={styles.spacer} aria-hidden="true">
            {children}
          </span>
          <span className={styles.label}>{children}</span>
          <span className={styles.arrow}>
            <SmallArrow className="text-12" />
          </span>
        </>
      )}
    </button>
  )
}

function fullWidthClassName(
  fullWidth: 'sm' | 'md' | 'lg' | false = 'sm',
): string | null {
  switch (fullWidth) {
    case 'sm':
      return 'w-full sm:w-fit'
    case 'md':
      return 'w-full md:w-fit'
    case 'lg':
      return 'w-full lg:w-fit'
    case false:
      return null
  }
}
