'use client'

import { memo } from 'react'
import ReactDOM from 'react-dom'

export const DatoPreconnect = memo(function DatoPreconnect() {
  // @ts-ignore
  ReactDOM.preconnect('https://www.datocms-assets.com')

  return null
})
