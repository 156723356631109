import { cx } from 'lib/cx'
import { TestimonialModelFragment } from '.generated/dato'
import DatoImage from './DatoImage'
import { Quote } from './Quote'

export function Testimonial(props: {
  testimonial: TestimonialModelFragment
  children?: React.ReactNode
}) {
  return (
    <div className="flex flex-col md:flex-row gap-32 mx-24">
      <figure className="rounded-full overflow-hidden w-224 h-224 min-w-224 self-center relative">
        <DatoImage
          file={props.testimonial.image}
          fill
          style={{ objectFit: 'contain' }}
          placeholder="blur"
        />
      </figure>
      <Quote quote={props.testimonial.quote} author={props.testimonial.person}>
        {props.children}
      </Quote>
    </div>
  )
}
