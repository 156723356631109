import { cx } from 'lib/cx'
import Image from 'next/image'
import file from 'public/scribbles/finanzcheck.svg'

export default function FinanzcheckBrand(props: { className?: string }) {
  return (
    <div className={cx(props.className, 'h-[1em] w-[8em] relative')}>
      <Image src={file} alt="FINANZCHECK.de" fill />
    </div>
  )
}
